.chatbot-preview-card {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    width: 100%;
    padding: 1rem;
    box-shadow: var(--tab-box-shadow);
    border-radius: 1rem;
    border: solid 1px var(--primary-color-500);
}

.chatbot-preview-card > .row {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    gap: 1rem;
}

.chatbot-preview-card > .row.filler {
    flex: 1 1 auto;
}

.chatbot-preview-card > .row > * {
    flex: 0 0 auto;
    max-width: 100%;
}

.chatbot-preview-card > .row:last-child {
    justify-content: flex-end;
}

.chatbot-preview-card > .row > .title {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chatbot-preview-card > .row > .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.chatbot-preview-card > .row > .favorite-button {
    height: 1.5rem;
    width: 1.5rem;
}

.chatbot-preview-card > .row > button {
    width: 9rem;
    padding: 0.5rem 0;
    font-size: 0.875rem;
}



