span.citation-link {
    display: inline-block;
    height: 1rem;
    font-size: 1rem;
    color: rgb(255, 145, 76);
    margin: 0 0.25rem;
    cursor: pointer;
    font-style: normal;
    font-family: "Consolas", monospace;
    font-weight: 500;
    background: transparent;
}
.citation-link:hover {
    color: rgb(0, 73, 176);
}