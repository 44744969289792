.content-area.user-management {
    min-height: 100%;
}

.operation-area.user-management {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.operation-area.user-management > button {
    width: 15rem;
}