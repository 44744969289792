.main-panel-layout {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    padding: 1.5rem;
    gap: 1.5rem;
    background-color: var(--primary-fg-color);
    box-shadow: var(--tab-box-shadow);
    overflow: hidden;
}

.main-panel-layout > .title-area-wrapper {
    flex: 0 0 auto;
    width: 100%;
    overflow:hidden;
}

.main-panel-layout > .content-area-wrapper {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: visible;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    animation: fade-in 0.3s ease-in-out;
}

.main-panel-layout > .operation-area-wrapper {
    flex: 0 0 auto;
    width: 100%;
    overflow:hidden;
    animation: fade-in 0.3s ease-in-out;
}