.loading-view { /* This is the class that is used to hide the content area when the loading screen is displayed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
}

.loading-view > svg {
    width: 10rem;
    height: 10rem;
}

.loading-view > h1,
.loading-view > h1 > span {
    color: var(--primary-color-400);
    transition: opacity 0.15s ease-in-out;
}