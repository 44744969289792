.expandable-textarea-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    background-color: white;
    border: 1px solid var(--primary-color-500);
    border-radius: 0.5rem;
}

.expandable-textarea-wrapper:focus-within {
    outline: 1px solid var(--primary-color-900);
    border: 1px solid var(--primary-color-500);
}

.expandable-textarea-wrapper > .expandable-textarea {
    display: block;
    width: 100%;
    background-color: white;
    font-size: 1rem;
    resize: none;
    margin: 0;
    padding: 0;
    line-height: 1.5rem;
    min-height: calc( 3 * 1.5rem );
    overflow: hidden;
    font-weight: 500;
    font-family: 'Satoshi-Variable', sans-serif;
    border: none;
    outline: none;
}

.expandable-textarea-wrapper > .expandable-textarea:focus {
    border: none;
    outline: none;
}