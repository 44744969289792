.auth-form {
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: var(--form-box-shadow);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 90%;
}

.auth-form > .input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    --input-height: 2rem;
    --input-padding-h: 0.5rem;
    --input-padding-v: 0rem;
    --icon-height: 1.25rem;
    --icon-width: 1.25rem;
}

.auth-form > .input-group > label {
    display: block;
    font-size: 1rem;
    font-weight: 600;
}

.auth-form > .input-group > input {
    display: block;
    width: 20rem;
    max-width: 100%;
    border-radius: 0.5rem;
    height: var(--input-height);
    padding: var(--input-padding-v) calc(var(--input-padding-h) + var(--icon-width)) var(--input-padding-v) var(--input-padding-h);
    border: 1px solid var(--primary-color-900);
    background-color: transparent;
}

.auth-form > .input-group > input:focus {
    outline: 1px solid var(--primary-color-900);
    border: 1px solid var(--primary-color-500);
}

.auth-form > .input-group > .show-password {
    position: absolute;
    bottom: calc(calc(var(--input-height) - var(--icon-height)) / 2);
    right: var(--input-padding-h);
    height: var(--icon-height);
    width: var(--icon-width);
    cursor: pointer;
}

.auth-form > .input-group > .show-password > svg {
    height: 100%;
    width: 100%;
    stroke: var(--primary-color-500);
    transition: stroke 0.2s ease-in-out;
}

.auth-form > .input-group > .show-password > svg:hover {
    stroke: var(--primary-color-900);
}

.auth-form > .links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.auth-form > .links > p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: var(--primary-color-500);
    text-decoration: underline;
}

.auth-form > button[type="submit"] {
    width: 100%;
    padding: 0.5rem 0;
    border: 0;
    outline: 0;
    border-radius: 0.5rem;
    background-color: var(--primary-color-700);
    font-size: 1.25rem;
    color: var(--primary-color-50);
    font-weight: 600;
}

.auth-form > button[type="submit"]:hover {
    background: var(--primary-color-500);
}

.auth-form > button[type="submit"][disabled] {
    background-color: var(--primary-color-400);
    cursor: not-allowed;
}