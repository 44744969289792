.title-area.knowledge-base-studio > .title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.3s ease-in-out;
}

.title-area.knowledge-base-studio > .title > span:first-child {
    color: var(--primary-color-900);
}

.title-area.knowledge-base-studio > .title > span:last-child {
    color: var(--primary-color-500);
    font-size: 1.25rem;
}

.title-area.knowledge-base-studio > .title > svg {
    height: 1.25rem;
    width: 1.25rem;
}

.title-area.knowledge-base-studio > .title > svg > path {
    fill: var(--primary-color-500);
}

.content-area.knowledge-base-studio {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0 0.5rem;
}