.sample-prompt-list {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: fade-in 0.3s ease-in-out;
}

.sample-prompt-list > * {
    margin-top: 2rem;
}

.sample-prompt-list > h2 {
    color: var(--primary-color-800);
}

.sample-prompt-list > .sample-prompts {
    display: grid;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, minmax(20rem, 1fr));
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 75%;
}

@media (max-width: 1200px) {
    .sample-prompt-list > .sample-prompts {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
}

.sample-prompt-list .sample-prompt {
    width: 100%;
    padding: 1rem;
    height: calc(1rem * 2 + 1rem * 2 * 1.25);
    line-height: 1.25rem;
    border: 1px solid var(--primary-color-500);
    border-radius: 0.5rem;
    box-shadow: var(--tab-box-shadow);
}

.sample-prompt-list .sample-prompt > p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.sample-prompt-list .sample-prompt:hover {
    background-color: var(--primary-color-100);
    border-color: var(--primary-color-900);
}

.sample-prompt-list > .hint {
    color: var(--primary-color-500);
}