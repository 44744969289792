
.chat-input-field {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border: 2px solid var(--primary-color-600);
    border-radius: 0.5rem;
    transition: all 0.15s ease-in-out;
}

.chat-input-field:focus-within {
    border: 2px solid var(--primary-color-900);
}

.chat-input-field > * {
    flex: 0 0 auto;
}

.chat-input-field > .text-area {
    flex: 1 1 auto;
    border: none;
    outline: none;
    padding: 0;
    background: transparent;
    font-size: 1rem;
    min-height: 1.5rem;
    max-height: calc(5 * 1.5rem);
    resize: none;
    margin: 0;
    font-family: sans-serif;
    line-height: 1.5rem;
    overflow: auto;
}

.chat-input-field > .text-area:focus {
    border: none;
    outline: none;
}

.chat-input-field > .button {
    width: 1.5rem;
    height: 1.5rem;
}

.chat-input-field > .button > * {
    height: 100%;
    width: 100%;
    transition: all 0.2s ease-in-out;
    stroke: var(--primary-color-500);
}

.chat-input-field > .button:hover > * {
    stroke: var(--primary-color-900);
    fill: var(--primary-color-400);
}

.chat-input-field > .button.disabled {
    cursor: not-allowed;
}

.chat-input-field > .button.disabled > * {
    stroke: var(--primary-color-900);
    fill: var(--primary-color-300);
}