.custom-react-menu-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 1.75rem;
    gap: 0.5rem;
    padding: 0;
    transition: all 0.2s ease-in-out;
}

.custom-react-menu-item > .icon-wrapper {
    width: 1.125rem;
    max-width: 1.125rem;
    height: 1.125rem;
    max-height: 1.125rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.custom-react-menu-item > .icon-wrapper > svg {
    width: 100%;
    height: 100%;
}

.custom-react-menu-item > .text-wrapper {
    font-size: 0.875rem;
    font-weight: 500;
}