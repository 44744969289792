.code-block,
.code-block *,
.inline-code-block,
.inline-code-block *{
    color: unset;
    font-family: "Consolas", monospace !important;
    font-weight: 500 !important;
}

.code-block {
    border-radius: 0.5rem;
    background-color: var(--primary-color-800);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    max-width: 100%;
}

.code-block > *:not(.header) {
    margin: 0 !important;
    padding: 1rem !important;
    border-radius: 0 !important;
    background-color: transparent !important;
}

@media print {
    .code-block > *:not(.header) {
        overflow: hidden !important;
        word-break: break-all;
    }

    .code-block > *:not(.header) code {
        white-space: pre-wrap !important;
        word-break: break-all !important;
        overflow: hidden !important;
        max-width: 100% !important;
    }
}

.code-block > * * {
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
}

.code-block > .header {
    padding: 0.5rem 1rem;
    margin: 0;
    background-color: var(--primary-color-700);
    color: var(--primary-color-50);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.code-block > .header > .copy-button {
    height: 1.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    gap: 0.25rem;
    color: var(--primary-color-300);
    transition: all 0.15s ease-in-out;
}

.code-block > .header > .copy-button:hover {
    color: var(--primary-color-50);
}

.code-block > .header > .copy-button > svg {
    height: 1.25rem;
    width: auto;
    stroke: var(--primary-color-300);
}

.code-block > .header > .copy-button:hover > svg {
    stroke: var(--primary-color-50);
}

.linenumber.react-syntax-highlighter-line-number {
    padding: 0 1rem 0 0 !important;
    border-radius: 0 !important;
    min-width: 1rem !important;
}