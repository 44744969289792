.not-found-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0; 
    padding: 0;
    width: 100%;
    height: 100vh;
    position: relative;
}

.not-found-page > * {
    z-index: 1;
}

.not-found-page > .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.not-found-page > .content {
    padding: 3rem;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(6px);
    max-width: 90%;
}

.not-found-page h1 {
    font-weight: lighter;
    margin: 0;
    padding: 1.25rem;
    font-size: 12.5rem;
    background: linear-gradient(to left, var(--primary-color-700), var(--primary-color-900));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}

.not-found-page h2 {
    text-align: center;
}

.not-found-page p {
    text-align: center;
}

.not-found-page span {
    font-weight: bold;
    background: linear-gradient(to left, var(--primary-color-700), var(--primary-color-900));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}

@media only screen and (max-width: 600px) {
    .not-found-page {
        padding: 0 24px;
    }

    .not-found-page h1 {
        font-size: 100px;
    }
    
    .not-found-page h2 {
        font-size: 20px;
        margin: 8px 0;
    }
    
    .not-found-page p {
        margin: 8px 0;
    }
}
