.file-manager {
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    gap: 1.5rem;
    animation: fade-in 0.3s ease-in-out;
}

.file-manager > .files-table,
.file-manager > .empty-files-table{
    flex: 1 1 auto;
}

.file-manager > .controls {
    flex: 0 0 auto;
}

.file-manager > .controls {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    padding: 0;
}

.file-manager > .controls > .refresh-button {
    padding: 0.25rem;
    width: 2rem;
    max-width: 2rem;
    height: 2rem;
    max-height: 2rem;
    background-color: var(--primary-color-50);
    border: 1px solid var(--primary-color-700);
    border-radius: 0.5rem;
    transition: background-color 0.2s ease-in-out;
    z-index: 10;
}

.file-manager > .controls > .refresh-button > svg {
    width: 100%;
    height: 100%;
}

.file-manager > .controls > .refresh-button > svg * {
    fill: var(--primary-color-800);
}

.file-manager > .controls > .file-upload-button {
    max-width: 15rem;
}

.file-manager > .controls > * {
    flex: 1 1 auto;
}