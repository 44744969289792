.floating-panel {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 100%);
    width: fit-content;

    background-color: white;
    border: none;
    outline: none;
    box-shadow: none;
    overflow: hidden;
    border-radius: 1rem;

    padding: 0;
    max-width: 90%;
    max-height: 90%;
    z-index: 10;
    opacity: 0;

    --duration: 0.3s;
    --delay: 0.05s;
}

.floating-panel.enter {
    animation: float-in-from-bottom var(--duration) var(--delay) forwards;
}

.floating-panel.leave {
    animation: float-out-to-bottom var(--duration) forwards;
}

.floating-panel > div.content {
    max-width: 100%;
    overflow: hidden;
}

.floating-panel-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    opacity: 0;
    z-index: 9;

    --duration: 0.3s;
    --delay: 0.05s;
}

.floating-panel-background.enter {
    animation: fade-in var(--duration) var(--delay) forwards;
}

.floating-panel-background.leave {
    animation: fade-out var(--duration) forwards;
}