.title-area.chat-center {
    width: 100%;
    max-width: 100%;
}

.title-area.chat-center > .chat-title {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.title-area.chat-center > .chat-title > .title-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    height: 2.1875rem;
    margin: 0;
    padding: 0 1rem;
    border-radius: 0.5rem;
    background: none;
    border: none;
    background: var(--primary-color-200);
    outline: none;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    animation: fade-in 0.3s ease-in-out;
}

.title-area.chat-center > .chat-title > .title-button:disabled {
    opacity: 0.5;
}

.title-area.chat-center > .chat-title > .title-button:disabled:hover {
    background: var(--primary-color-200);
}

.title-area.chat-center > .chat-title > .title-button > svg {
    height: 1rem;
    width: 1rem;
    stroke: var(--primary-color-900);
    stroke-width: 1;
}

.title-area.chat-center > .chat-title > .title-button:hover {
    background: var(--primary-color-300);
}

.content-area.chat-center {
    display:block;
    width: 100%;
    height: 100%;
    overflow: auto;
    animation: fade-in 0.3s ease-in-out;
}

.operation-area.chat-center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.operation-area.chat-center > .tool-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.operation-area.chat-center > .input-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}
