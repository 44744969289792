.tutorial-page {
    padding: 6rem 6rem 8rem 6rem;
    margin: 0 auto;
    max-width: 66rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4rem;
}

.tutorial-page > .title {
    width: 100%;
    text-align: center;
    color: var(--primary-highlight-color);
}

.tutorial-page > .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.tutorial-page > .section > .section-title {
    width: 100%;
    text-align: center;
    color: var(--secondary-highlight-color);
}


.tutorial-page > .section > .section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
}

.tutorial-page > .section > .section-content > .video-container {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    padding-top: calc(1080 / 1920 * 100%)
}

.tutorial-page > .section > .section-content > .video-container > .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.tutorial-page > .section > .section-content > p {
    text-align: center;
}