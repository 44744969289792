.app-page-layout {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
    overflow: visible;
    gap: 0.5rem;
}

.app-page-layout > .side-panel-wrapper {
    flex: 0 0 15rem;
    max-width: 15rem;
    height: 100%;
    max-height: 100%;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    gap: 0.5rem;
}

.app-page-layout > .main-panel-wrapper {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
}

.app-page-layout > .main-panel-wrapper > * {
    flex: 1 1 0;
    width: 0;
}