.tab.remaining-balance {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0.5rem;
    gap: 0.5rem;
    background-color: var(--primary-fg-color);
    box-shadow: var(--tab-box-shadow);
}

.tab.remaining-balance > h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}