.chat-message-grid {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
    gap: 1rem;
    padding-right: 0.5rem;
    animation: fade-in 0.3s ease-in-out;
    max-width: 100%;
}

@media print {
    .chat-message-grid {
        padding-right: 0;
    }
}

.chat-message-grid * {
    -webkit-print-color-adjust: exact !important;
}

.chat-message-grid:not(:first-child) {
    margin-top: 1.5rem;
}

.chat-message-grid:before {
    content:"\A";
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #dd4397 transparent transparent;
    position: absolute;
    left: -15px;
}

.assistant-avatar,
.user-avatar {
    flex: 0 0 auto;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.user-avatar {
    background-color: #D1C5BC;
}

.assistant-avatar {
    background-color: #BCC2D1;
}

.assistant-avatar > svg,
.user-avatar > svg {
    height: 60%;
    width: 60%;
}

.assistant-avatar > svg > path,
.user-avatar > svg > path {
    fill: var(--primary-color-50);
}

.chat-message-grid.user > .assistant-avatar {
    visibility: hidden;
}

.chat-message-grid.assistant > .user-avatar {
    visibility: hidden;
}

.chat-message-grid > .chat-message-bubble-container {
    flex: 1 1 auto;
    width: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.chat-message-grid.user > .chat-message-bubble-container {
    justify-content: flex-end;
}

.chat-message-grid > .chat-message-bubble-container > .chat-message-bubble {
    background-color: var(--primary-color-50);
    border-radius: 0.5rem;
    padding: 1rem;
    border: solid 1px var(--primary-color-500);
    box-shadow: var(--tab-box-shadow);
    width: fit-content;
    min-width: 0;
    max-width: 95%;

}

.chat-message-grid > .chat-message-bubble-container > .chat-message-bubble > .chat-message-content-wrapper {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.chat-message-grid .chat-message-content p {
    white-space: pre-wrap;
}

.chat-message-grid .chat-message-content p.raw-message {
    --line-height: 1.5rem;
    white-space: pre-wrap;
    line-height: var(--line-height);
    margin: 0;
}