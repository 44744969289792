.home-page {
    width: 100%;
    overflow: hidden;
}

.home-page > section {
    padding: 0 6rem;
    margin: 6rem auto;
    max-width: 70rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.home-page > .separator {
    width: 6rem;
    height: 1rem;
    background: var(--primary-color-300);
    border-radius: 0.5rem;
    margin: 0 auto;
}

.home-page .separation-line {
    width: 100%;
    height: 1px;
    background: var(--primary-color-700);
}

.home-page > section.entry > .sub-title {
    font-size: 1.5rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin: 0 0 0 0;
    color: var(--primary-color-500);
}

.home-page > section.entry > .description {
    width: 100%;
    font-size: 1.5rem;
    max-width: 54rem;
    text-align: center;
    margin: 0 0 0 0;
    font-weight: 500;
    color: var(--primary-color-500);
}

.home-page > section.entry > .join-now-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30rem;
    max-width: 80%;
    height: 3.5rem;
    border: none;
    outline: none;
    border-radius: 2rem;
    background: linear-gradient(45deg , #FFAD72, #FF742C);
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary-fg-color);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.home-page > section.entry > .join-now-button:hover {
    opacity: 0.9;
    transform: scale(1.05);
}

.home-page > section.features {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 3rem;
}

.home-page > section.features > .title {
    font-weight: 700;
    color: var(--primary-color-700);
}

.home-page > section.features > .feature-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    grid-row-gap: 3rem;
    grid-column-gap: 3rem;
    background: transparent;
}

.home-page > section.features > .feature-list > .feature {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    height: 100%;
}

.home-page > section.features > .feature-list > .feature > .icon {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    border: 0.35rem solid #F6944D;
    box-shadow: 0.25rem 0.25rem 1rem 2px var(--primary-color-300);
    background: var(--primary-color-50);
}

.home-page > section.features > .feature-list > .feature > .icon > img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.home-page > section.features > .feature-list > .feature > .title {
    font-weight: 700;
    color: var(--primary-color-700);
    white-space: nowrap;
}

.home-page > section.features > .feature-list > .feature > .description {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--primary-color-500);
    text-align: center;
    max-width: 90%;
}

.home-page > section.about {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 3rem;
}

.home-page > section.about > .right-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    max-width: calc(100% - 3rem - 16rem);
    min-width: 20rem;
}

.home-page > section.about > .right-box > .title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--primary-color-700);
}

.home-page > section.about > .right-box > .description {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-color-500);
    text-align: justify;
}

.home-page > section.about > .right-box > .description > i {
    color: var(--primary-color-500);
}

.home-page > section.demo-video {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
}

.home-page > section.demo-video > .title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--primary-color-700);
}

.home-page > section.demo-video > .video {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    padding-top: calc(483 / 854 * 100%)
}

.home-page > section.demo-video > .video > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home-page > section.supported-by > .logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    max-height: 2.5rem;
    margin-top: 2rem;
}

.home-page > section.supported-by > .logos > a {
    text-decoration: none;
    transition: transform 0.2s ease-in-out;
}

.home-page > section.supported-by > .logos > a:hover {
    transform: scale(1.05);
}

.home-page > section.supported-by > .logos > a  > h1 {
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--primary-color-700);

}

.home-page > section.supported-by > .logos > a > img {
    height: 2.25rem;
    width: auto;
}

.home-page > section.footer {
    max-width: 86rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.home-page > section.footer > .organizations {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.home-page > section.footer > .organizations > .title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--primary-color-700);
}

.home-page > section.footer > .organizations > .logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.home-page > section.footer > .organizations > .logos  img {
    height: 4rem;
    width: auto;
}

.home-page > section.footer > .links-and-disclaimer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-auto-flow: row;
    grid-row-gap: 0;
    grid-column-gap: 1rem;
    background: transparent;
}

.home-page > section.footer > .links-and-disclaimer > .links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.home-page > section.footer > .links-and-disclaimer > .links > a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home-page > section.footer > .links-and-disclaimer > .disclaimer {
    font-size: 1rem;
    font-weight: 500;
    color: var(--primary-color-500);
    margin: 0;
    text-align: right;
    line-height: 1.25;
}