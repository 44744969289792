.app-layout {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
    margin: 0;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0.5rem;
    gap: 0.5rem;
    background-color: var(--primary-bg-color);
    overflow: hidden;
    animation: none !important;
}

.app-layout > * {
    opacity: 1;
    transition: all 0.5s ease;
}

.app-layout.hidden > * {
    opacity: 0;
}

.app-layout > aside {
    flex: 0 0 auto;
    display: flex;
    height: 100%;
    max-height: 100%;
    overflow: visible;
}

.app-layout > section {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
    gap: 0.5rem;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow: visible;
}
