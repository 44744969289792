.edit-form {
    display: grid;
    grid-template-columns: [labels] auto [controls] 1fr;
    grid-auto-flow: row;
    grid-row-gap: 1.5rem;
    grid-column-gap: 1rem;
    padding: 0.5rem 0;
    background: transparent;
    width: 100%;
    max-width: 45rem;
    min-width: 26rem;
    animation: fade-in 0.3s ease-in-out;
}

.edit-form > * {
    grid-column: controls;
    grid-row: auto;
}

.edit-form > label  {
    grid-column: labels;
    grid-row: auto;
}

.edit-form > .title {
    grid-column: labels / span 2;
    grid-row: auto;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color-900);
    background-color: transparent;
    padding-bottom: 1.5rem;
    text-align: left;
}

.edit-form > * {
    width: 100%;
    background-color: var(--primary-color-50);
    min-width: 0;
    max-width: 100%;
}

.edit-form > label {
    font-size: 1.15rem;
    font-weight: 600;
    color: var(--primary-color-900);
    background-color: transparent;
}

.edit-form > input,
.edit-form > textarea {
    display: block;
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid var(--primary-color-500);
    background-color: white;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    resize: none;
    margin: 0;
    line-height: 1.5rem;
    overflow: auto;
    font-weight: 500;
    font-family: 'Satoshi-Variable', sans-serif;
}

.edit-form > input[disabled] {
    background-color: var(--primary-color-100);
}

.edit-form > textarea {
    height: calc(3 * 1.5rem);
}

.edit-form > input:focus,
.edit-form > textarea:focus {
    outline: 1px solid var(--primary-color-900);
    border: 1px solid var(--primary-color-500);
}

.edit-form > .module {
    grid-column: labels / span 2;
    grid-row: auto;
    width: 100%;
}

.edit-form > .module > .control {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    background-color: transparent;
}

.edit-form > .module > .control > button {
    min-width: 7rem;
    padding: 0.5rem 2rem;
}

.edit-form > .button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 2rem;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    padding-top: 2rem;
    background-color: transparent;
}

.edit-form > .button-group > button {
    min-width: 9rem;
    padding: 0.5rem 2rem;
    font-size: 1.25rem;
}

.required:after {
    content:" *";
    color: red;
}