.guide-page {
    padding: 6rem 6rem 8rem 6rem;
    margin: 0 auto;
    max-width: 66rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4rem;
}

.guide-page > .title {
    width: 100%;
    text-align: center;
}

.guide-page > .content {
    width: 100%;
    animation: fade-in 0.3s ease-in-out;
}

.guide-page h1 {
    color: var(--primary-highlight-color);
}

.guide-page h2 {
    color: var(--secondary-highlight-color);
}