.fixed-height-layout {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    background: var(--primary-fg-color);
    overflow-x: hidden;
    overflow-y: visible;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
}

.fixed-height-layout > header {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    position: relative;
}

.fixed-height-layout > main {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.fixed-height-layout > main > * {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    animation: fade-in 0.3s ease-in-out;
}
