.main-panel-back-title {
    width: 100%;
    max-width: 100%;
}

.main-panel-back-title > .back-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
    padding: 0;
    transition: all 0.2s ease-in-out;
    width: fit-content;
}

.main-panel-back-title > .back-button:hover {
    transform: translate(-0.25rem, 0);
}

.main-panel-back-title > .back-button > .icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-panel-back-title > .back-button > .icon > svg {
    width: 75%;
    height: 75%;
    max-width: 75%;
    max-height: 75%;
}