.user-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 1rem;
    position: relative;
}

.user-menu > .name {
    font-weight: 500;
    color: var(--primary-color-700);
    font-size: 1.125rem;
}

.user-menu > .favicon {
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    background-color: var(--primary-color-400);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: var(--primary-color-100);
    font-size: 1.125rem;
}