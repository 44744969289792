.title-area.chatbot-details > .back-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
    padding: 0;
    transition: all 0.2s ease-in-out;
}

.title-area.chatbot-details > .back-button:hover {
    transform: translate(-0.25rem, 0);
}

.title-area.chatbot-details > .back-button > .icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-area.chatbot-details > .back-button > .icon > svg {
    width: 75%;
    height: 75%;
    max-width: 75%;
    max-height: 75%;
}

.content-area.chatbot-details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
}

.content-area.chatbot-details > .info-tab {
    width: 30rem;
    max-width: 30rem;
    height: 35rem;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.content-area.chatbot-details > .info-tab h2 {
    font-size: 1.75rem;
    line-height: calc(1.75rem * 1.25);
    height: calc(1.75rem * 1.25);
    width: 100%;
}

.content-area.chatbot-details > .info-tab p {
    margin: 0;
    padding: 0;
}

.content-area.chatbot-details > .info-tab ol {
    margin: 0;
    padding: 0  0 0 1.25rem;
}

.content-area.chatbot-details > .info-tab > .row:last-child {
    margin-top: auto;
}

.content-area.chatbot-details > .info-tab > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.content-area.chatbot-details > .info-tab > .row > .tag {
    display: inline-block;
    flex-direction: row;
    white-space: nowrap;
}

.content-area.chatbot-details > .info-tab > .col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
}

.content-area.chatbot-details > .info-tab > .col > p {
    line-height: calc(1rem * 1.25);
    min-height: calc(1rem * 1.25);
    height: fit-content;
    min-width: 100%;
}

.content-area.chatbot-details > .info-tab > .col > ol {
    margin: 0;
    width: 100%;
}

.content-area.chatbot-details > .info-tab > .col > ol > li {
    width: 100%;
}

.content-area.chatbot-details > .info-tab > .row > .favorite-button {
    height: 1.5rem;
    width: 1.5rem;
}

.content-area.chatbot-details > .info-tab > .row > .button {
    width: 40%;
    padding: 0.5rem 0;
    font-size: 1.25rem;
}