.about-page {
    padding: 6rem 6rem 8rem 6rem;
    margin: 0 auto;
    max-width: 66rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4rem;
}

.about-page > h1 {
}

.about-page > h2 {
}

.about-page > .title {
    width: 100%;
    text-align: center;
    color: var(--primary-highlight-color);
}

.about-page > .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.about-page > .section > .section-title {
    width: 100%;
    text-align: left;
    color: var(--secondary-highlight-color);

}

.about-page > .section > .section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 2rem;
}

.about-page > .section > .section-content > .sub-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.about-page > .section > .section-content > .sub-section > .sub-section-title {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.about-page > .section > .section-content > .sub-section > .sub-section-title > svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--primary-color-400);
    transition: all 0.3s ease-in-out;
}

.about-page > .section > .section-content > .sub-section > .sub-section-title:hover > svg {
    fill: var(--primary-color-700);
}

.about-page > .section > .section-content > .sub-section > .sub-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    gap: 3rem;
}

.about-page > .section > .section-content > .sub-section > .sub-section-content > .left-box {
    flex: 1 1 0;
}

.about-page > .section > .section-content > .sub-section > .sub-section-content > .left-box > p {
    white-space: pre-wrap;
}


.about-page > .section > .section-content > .sub-section > .sub-section-content > .right-box {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-height: 12.5rem;
}

.about-page > .section > .section-content > .sub-section > .sub-section-content > .right-box > img {
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 50%;
    object-fit: cover;
}