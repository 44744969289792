.side-panel-layout {
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;
    min-height: 0;
    padding: 0.5rem;
    background-color: var(--primary-fg-color);
    border-radius: 0.5rem;
    box-shadow: var(--tab-box-shadow);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    gap: 1rem;
    overflow: visible;
}

.side-panel-layout > .title {
    flex: 0 0 auto;
    margin-left: 0.5rem;
    width: 100%;
}

.side-panel-layout > .content {
    flex: 1 1 auto;
    overflow-x: visible;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    left: 0;
    top: 0;
    width: calc(100% + 0.5rem);
    padding-right: 0.5rem;
}

.side-panel-layout > .button {
    flex: 0 0 auto;
    width: 100%;
    transition: all 0.2s ease-in-out;
    animation: fade-in-with-expand 0.3s ease-in-out;
}