.editable-chat-title {
    width: 100%;
    max-width: 100%;
}

.editable-chat-title > input {
    font-size: 1.75rem;
    line-height: 1.25;
    font-weight: 600;
    max-width: 20rem;
    width: 100%;
    min-width: 0;
    font-family: "Satoshi-Variable", sans-serif;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid transparent;
}

.editable-chat-title > input:hover {
    border-bottom: 2px solid var(--primary-color-400);
}

.editable-chat-title > input:focus {
    outline: none;
    border-bottom: 2px solid var(--primary-color-900);
}