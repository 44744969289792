.vertical-layout {
    width: 100%;
    height: 100%;
    background: var(--primary-fg-color);
    overflow-x: hidden;
    overflow-y: scroll;
}

.vertical-layout > header {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    position: sticky;
    top: 0;
    z-index: 10;
}

.vertical-layout > main {
    width: 100%;
    height: calc(100% - 3rem);
}

.vertical-layout > main > * {
    animation: fade-in 0.3s ease-in-out;
}
