.default-main-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 23rem;
}

.default-main-panel > .logo,
.default-main-panel > .tag {
    font-size: 7rem;
    color: rgba(220,226,232, 0.8);
    text-shadow: 2px 2px 4px #ffffff, 0 0 0 #000, 2px 2px 4px #ffffff;
    text-align: center;
    animation: fade-in-with-shrink 0.3s ease-in-out forwards;
}

.default-main-panel > .tag {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;
    font-weight: 700;
}