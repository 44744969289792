.faq-page {
    padding: 6rem 6rem 8rem 6rem;
    margin: 0 auto;
    max-width: 66rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4rem;
}

.faq-page > div {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.faq-page h1 {
    color: var(--primary-highlight-color);
}

.faq-page h4 {
    color: var(--secondary-highlight-color);
}