button.primary-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 0;
    outline: 0;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    background-color: var(--primary-color-700);
    color: var(--primary-color-50);
    transition: background-color 0.2s ease-in-out;
}

button.primary-button:hover {
    background-color: var(--primary-color-500);
}

button.primary-button:active {
    background-color: var(--primary-color-400);
}