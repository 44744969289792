.side-navigation-bar {
    width: auto;
    height: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: var(--primary-color-50);
    box-shadow: var(--tab-box-shadow);
}

.side-navigation-bar > .items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.side-navigation-bar > .items > .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.25rem;
    gap: 0.125rem;
    visibility: hidden;
    opacity: 0;
}

.side-navigation-bar > .items > .item.bottom {
    margin-top: auto;
}

.side-navigation-bar > .items > .item:hover > .icon-wrapper {
    box-shadow: 0 0 4px 0 var(--primary-color-900);
}

.side-navigation-bar > .items > .item.selected > .icon-wrapper {
    border: 1px solid var(--primary-color-900);
    box-shadow: 0 0 4px 0 var(--primary-color-900);
}

.side-navigation-bar > .items > .item > .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem;
    border-radius: 0.5rem;
    border: 1px solid var(--primary-fg-color);
    box-shadow: 0 0 4px 0 var(--primary-fg-color);
    transition: all 0.3s ease-in-out;
}

.side-navigation-bar > .items > .item > .icon-wrapper > * {
    width: 2rem;
    height: 2rem;
}

.side-navigation-bar > .items > .item > .text {
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
}

