
.pagination-controller {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.pagination-controller > .page-number {
    border-radius: 0.5rem;
    padding: 0.3rem 0.75rem;
    background-color: var(--primary-color-50);
    border: 1px solid var(--primary-color-500);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.pagination-controller > .page-number > * {
    width: 3rem;
    padding: 0 0.25rem;
}

.pagination-controller > .page-number > input {
    margin: 0;
    font-size: 1rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    outline: none;
    text-align: right;
    border-radius: 0.25rem;
}

.pagination-controller > .page-number > input:hover,
.pagination-controller > .page-number > input:focus {
    background-color: var(--primary-color-200);
}

.pagination-controller > .button {
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    padding: 0.25rem;
    background-color: var(--primary-color-50);
    border: 1px solid var(--primary-color-500);
}

.pagination-controller > .button:hover {
    background-color: var(--primary-color-200);
}

.pagination-controller > .button:active {
    background-color: var(--primary-color-300);
}

.pagination-controller > .button > svg {
    width: 100%;
    height: 100%;
}