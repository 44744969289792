
.side-panel-item {
    border-radius: 0.5rem;
    transition: all 0.3s ease-in-out;
}

.side-panel-item {
    animation: fade-in-with-pop-up 0.3s ease-in-out;
}

.side-panel-item:hover {
    background-color: var(--primary-color-200);
}

.side-panel-item.selected {
    background-color: var(--primary-color-300);
}

.side-panel-item {
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    max-height: 4.2rem;
}

.side-panel-item > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    gap: 0.5rem;
}

.side-panel-item > .row > *:first-child {
    flex: 1 1 auto;
}

.side-panel-item > .row > *:last-child {
    flex: 0 0 auto;
}

.side-panel-item > .row > .title,
.side-panel-item > .row > .description,
.side-panel-item > .row > .tag {
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.side-panel-item > .row > .title {
    font-size: 1rem;
    font-weight: 500;
    color: var(--primary-text-color);
}

.side-panel-item > .row > .more-options {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    max-height: 1.5rem;
    border-radius: 0.5rem;
    display: block;
}

.side-panel-item > .row > .more-options > svg {
    transition: all 0.3s ease-in-out;
    fill: var(--primary-color-500);
}

.side-panel-item > .row > .more-options:hover > svg {
    fill: var(--primary-color-900);
}

.side-panel-item > .row > .description,
.side-panel-item > .row > .tag{
    font-size: 0.875rem;
    color: var(--secondary-text-color);
}