div.markdown-view {
    display: block;
    width: 100%;
    --line-height: 1.5rem;
}

.markdown-view * {
    margin-top: var(--line-height);
    margin-bottom: var(--line-height);
}

.markdown-view > *:not(p) {
    animation: fade-in 0.3s ease-in-out;
}

.markdown-view *:first-child {
    margin-top: 0;
}

.markdown-view *:last-child {
    margin-bottom: 0;
}

.markdown-view p {
    white-space: wrap;
    line-height: var(--line-height);
}

.markdown-view img {
    max-width: 100%;
    object-fit: contain;
    width: auto;
    box-shadow: 0 0 0.5rem 0.25rem var(--primary-color-300);
    border-radius: 0.5rem;
}

.markdown-view ol, .markdown-view ul {
    white-space: wrap;
}

.markdown-view li {
    padding: 0;
    white-space: wrap;
}

.markdown-view table {
    border-collapse: separate;
    table-layout: auto;
    border-spacing: 0;
    min-width: 0;
    max-width: 100%;
    --border: 1px solid var(--primary-color-500);
    --border-radius: 0.5rem;
}
.markdown-view table tr th,
.markdown-view table tr td {
    border-right: var(--border);
    border-bottom: var(--border);
    padding: 0.5rem 0.75rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
}

.markdown-view table tr th:first-child,
.markdown-view table tr td:first-child {
    border-left: var(--border);
}

.markdown-view table tr th {
    background: var(--primary-color-200);
    border-top: var(--border);
    text-align: center;
}

/* top-left border-radius */
.markdown-view table tr:first-child th:first-child {
    border-top-left-radius: var(--border-radius);
}

/* top-right border-radius */
.markdown-view table tr:first-child th:last-child {
    border-top-right-radius: var(--border-radius);
}

/* bottom-left border-radius */
.markdown-view table tr:last-child td:first-child {
    border-bottom-left-radius: var(--border-radius);
}

/* bottom-right border-radius */
.markdown-view table tr:last-child td:last-child {
    border-bottom-right-radius: var(--border-radius);
}