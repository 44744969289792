.contact-page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    border:none;
    z-index: 0;
}

.background img {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border:none;
    object-fit: cover;
    filter: brightness(0.5);
}

.contact-page h1 {
    color: var(--primary-highlight-color);
}

.contact-page > .content {
    position: relative;
    padding: 4rem;
    margin: 6rem auto 8rem auto;
    border-radius: 2rem;
    max-width: 90%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    background: rgba(255, 255, 255, .8);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}

