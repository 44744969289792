@keyframes float-in-from-bottom {
  0% {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes float-out-to-bottom {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
}

@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

@keyframes fade-in-with-pop-up {
    from {
        opacity: 0;
        transform: translateY(0.5rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in-with-expand {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade-in-with-shrink {
    from {
        opacity: 0;
        transform: scale(1);
        filter: blur(0.5rem);
    }
    to {
        opacity: 1;
        transform: scale(0.95);

    }
}