
.scrollable-table-wrapper {
    flex: 1 1 auto;
    width: 100%;
    overflow: auto;
    position: relative;
    padding-right: 1rem;
}

.scrollable-table-wrapper >.scrollable-table {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-auto-flow: row;
    grid-row-gap: 0;
    grid-column-gap: 0;
    background: transparent;
    overflow: visible;
}

.scrollable-table-wrapper > .scrollable-table > .table-cell {
    grid-row: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid var(--primary-color-500);
    background-color: transparent;
    transition: all 0.1s ease-in-out;
}

.scrollable-table-wrapper > .scrollable-table > .table-cell.hover {
    background-color: var(--primary-color-200);
}

.scrollable-table-wrapper >.scrollable-table > .table-cell.table-header {
    position: sticky;
    z-index: 1;
    top: 0;
    border-bottom: 2px solid var(--primary-color-900);
    background-color: var(--primary-color-50);
    font-size: 1.1rem;
    font-weight: 700;
}

.scrollable-table-wrapper > .scrollable-table  > .table-cell.buttons {
    display: flex;
    gap: 0.5rem;
}

.scrollable-table-wrapper > .scrollable-table  > .table-cell.buttons > .button {
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    padding: 0.25rem;
    background-color: var(--primary-color-50);
    border: 1px solid var(--primary-color-500);
}

.scrollable-table-wrapper > .scrollable-table  > .table-cell.buttons > .button:hover {
    background-color: var(--primary-color-200);
}

.scrollable-table-wrapper > .scrollable-table  > .table-cell.buttons > .button:active {
    background-color: var(--primary-color-300);
}

.scrollable-table-wrapper > .scrollable-table  > .table-cell.buttons > .button > svg {
    width: 100%;
    height: 100%;
}

.scrollable-table-wrapper > .scrollable-table  > .table-cell.text-button > .text-button {
    width: fit-content;
    height: 2rem;
    border-radius: 0.5rem;
    padding: 0.25rem 1rem;
    background-color: var(--primary-color-50);
    border: 1px solid var(--primary-color-500);
}

.scrollable-table-wrapper > .scrollable-table  > .table-cell.text-button > .text-button:hover {
    background-color: var(--primary-color-200);
}

.scrollable-table-wrapper > .scrollable-table  > .table-cell.text-button > .text-button:active {
    background-color: var(--primary-color-300);
}
