.top-navigation-bar {
    height: 3rem;
    padding: 0.5rem 1.5rem;
    background-color: var(--primary-fg-color);
    box-shadow: var(--tab-box-shadow);
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    gap: 1rem;
}

.top-navigation-bar > .title-wrapper {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.top-navigation-bar > .filler {
    flex: 1 1 0;
    height: 100%;
}

.top-navigation-bar > .menu-wrapper {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 1.5rem;
}

.top-navigation-bar > .menu-wrapper > .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 600;
    color: var(--primary-color-600);
    transition: all 0.2s ease-in-out;
}

.top-navigation-bar > .menu-wrapper > .menu-item:hover {
    transform: translate(0, -0.1rem) scale(1.05);
    color: var(--primary-color-900);
}

.top-navigation-bar > .menu-wrapper > .menu-item.selected {
    color: #ff914c;
}

.top-navigation-bar > .menu-wrapper > .menu-item > button {
    padding: 0.25rem 1rem;
    margin: 0;
    min-width: 5rem;
}

.top-navigation-bar > .user-menu-wrapper {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 1rem;
}

.top-navigation-bar > .title-wrapper > h3 {
    font-weight: 700;
}

.top-navigation-bar > .title-wrapper > .logo {
    height: 1.75rem;
    width: 1.75rem;
    object-fit: contain;
}