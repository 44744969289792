.content-area.chatbot-gallery {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-right: 1rem;
}

.content-area.chatbot-gallery > .tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.content-area.chatbot-gallery > .tags > .tag {
    border: solid 1px var(--primary-color-800);
    color: var(--primary-color-800);
    font-size: 0.875rem;
    padding: 0.25rem 1rem;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
}

.content-area.chatbot-gallery > .tags > .tag:hover {
    background: var(--primary-color-300);
}

.content-area.chatbot-gallery > .tags > .tag.selected {
    background: var(--primary-color-700);
    color: var(--primary-fg-color);
}

.content-area.chatbot-gallery > .chatbot-preview-cards {
    display: grid;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    gap: 1.5rem;
    width: 100%;
    animation: fade-in 0.3s ease-in-out;
}

